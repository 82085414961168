var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"-s2RvIUdMojMtEPA4RCNR"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { getEnvironment } from './app/utils/constants';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Replay may only be enabled for the client-side
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: true,
    }),
  ],
  environment: getEnvironment(process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT),
  ignoreErrors: ["(reading 'totalduration')", 'multitab.tabActivated', '[sessiondata.currentpage.pageid].totalduration'],
  beforeSend: function (e, hint) {
    if (hint && hint.originalException && hint.originalException.isAxiosError) {
      if (hint.originalException.response && hint.originalException.response.data) {
        let contexts = {
          ...e.contexts,
        };
        contexts.errorResponse = {
          data: hint.originalException.response.data,
        };
        e.contexts = contexts;
      }
    }
    return e;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
